<template>
  <section class="hero has-background-white is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="notification has-background-white has-text-centered">
          <h1 class="title">Getting everything ready</h1>
          <br>



          <progress class="progress is-large is-primary" :value="progress" :max="total"></progress>
          <br>





        </div>
        <!-- /Notification -->
      </div>
      <!-- /Container -->
    </div>
    <!-- /Hero Body -->
  </section>
</template>

<script>


      const ls = require('local-storage')

export default {
  name: "interstitial",


  data() {
    return {
      progress: 0,
      total: 5,
      timerId: null
    }
  },

  mounted() {


    this.progress = 0

    this.timerId = setInterval(() => this.doChecks(), 2000);





  },

  methods: {

    doChecks() {

      if (this.$store.state.currentUserDataNew?.getting) {

        return false;
      }

      this.progress = 1

      if (!this.$store.state.currentUserDataNew.domains) {

        return false;
      }

      this.progress = 2

      let domains = Object.keys(this.$store.state.currentUserDataNew.domains)

      let numDomains = domains.length

      if (numDomains == 0) {

        clearInterval(this.timerId);
        this.timerId = null

        this.$router.push("/noassociated")

        return false

      }



      console.log("Got here,,,,", numDomains, cDomain)

      if (numDomains == 1) {

          this.$store.commit("setCurrentDomain", domains[0])
          clearInterval(this.timerId);
          this.timerId = null
          this.$router.push("/home")
          return

      }

      clearInterval(this.timerId);
      this.timerId = null

      let cDomain = ls('currentDomain')


      if (cDomain && domains.includes(cDomain)) {
        this.$store.commit("setCurrentDomain", domains[0])
        this.$router.push("/home")
        return
      }

      this.$router.push("school/chooser")

    }

  }






};
</script>